import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/bootstrap/dist/css/bootstrap-grid.css");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/StoreProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/common/components/CustomToast/SuccessToast.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Feedback/Feedback.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Footer/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/MainHeader.module.css");
