"use client";
import { useState } from "react";
import styles from "./SuccessToast.module.css"; // Import the CSS module

let showToastFn: (message: string, type: "success" | "error") => void;

const ToastContainer = () => {
    const [toast, setToast] = useState<{ message: string; type: "success" | "error" } | null>(null);

    showToastFn = (message, type) => {
        setToast({ message, type });

        setTimeout(() => setToast(null), 2000);
    };

    if (!toast) return null; // Hide if no toast

    return (
        <div className={`${styles.toast} ${styles[toast.type]}`}>
            {toast.message}
        </div>
    );
};


export const successToast = (message: string) => showToastFn(message, "success");
export const errorToast = (message: string) => showToastFn(message, "error");

export default ToastContainer;
